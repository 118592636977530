import React, {useState, useEffect} from 'react';
import Seo from '../components/Seo';
import Helmet from 'react-helmet';
import {ReactSearchAutocomplete} from 'react-search-autocomplete'

export default ({pageContext, location}) => {
    const {seo = {}, header, results = []} = pageContext;
    const metaTitle = seo.metaTitle || 'Compare Hundreds Of Popular Medicines - Genexa';
    const openGraphTitle = seo.openGraphTitle || 'Compare Hundreds Of Popular Medicines - Genexa';
    const twitterTitle = seo.twitterTitle || 'Compare Hundreds Of Popular Medicines - Genexa';
    const brands = {};
    var locals = [];
    var inputstring = '';
    var filter = (obj) => {
        var r;
        var n;
        return n = obj.name, r = RegExp("" + obj.brand, "ig"), n.replace(r, "<strong class='brand-name'>$&</strong>");
    };
    results.forEach((obj, i) => {
        if (obj.brand != undefined && obj.hideFromWebsite !==true) {
            brands[obj.brand] = obj.brand_url;

        }
        var newobj = {url: obj.url, name: obj.name, brand: obj.brand}

        //var newobj={url:obj.url,name:obj.name,brand:obj.brand,description:<p dangerouslySetInnerHTML={{__html: filter(obj)}}></p>}
        locals[i] = newobj;
    });

   // console.log(results.length);
    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
      //  console.log(string, results)
    }

    const handleOnHover = (result) => {
        // the item hovered
       // console.log(result)
    }

    const handleOnSelect = (item) => {
        //console.log(this);
        // the item selected
        //console.log('selected');
        //console.log(item)
        window.location.href = item.url;
    }

    const handleOnFocus = () => {
       // console.log('Focused')
    }

    return (
        <React.Fragment>
            <Helmet title="Search - Genexa"/>
            <Seo
                metaTitle={metaTitle}
                metaDescription={seo.metaDescription}
                metaKeywords={seo.metaKeywords}
                openGraphTitle={openGraphTitle}
                openGraphDescription={seo.openGraphDescription}
                openGraphImage={seo.openGraphImage}
                twitterTitle={twitterTitle}
                twitterDescription={seo.twitterDescription}
                twitterImage={seo.twitterImage}
                pathname={location.pathname}
            />

            <div className="compare-search-container">
                <h1 className="brand-name">
                    What's in your medicines?
                </h1>
                <h3 className="comparesubhead">Compare Hundreds Of Popular Medicines</h3>
                <div className="compare-search2">
                    <ReactSearchAutocomplete
                        items={locals}
                        onSearch={handleOnSearch}
                        onHover={handleOnHover}
                        onSelect={handleOnSelect}
                        onFocus={handleOnFocus}
                        autoFocus={true}
                        showIcon={false}
                        showClear={false}
                        resultStringKeyName="name"
                        maxResults={10}
                        fuseOptions={{
                            threshold: 0.6,
                            isCaseSensitive: false,
                            shouldSort: true,
                            includeScore: false,
                        }}
                        inputSearchString={inputstring}
                        placeholder="Search Hundreds of Medicines..."
                    />
                </div>
                <div className="samples-flex">
                    <ul>
                        {Object.keys(brands).map(key =>
                            <li><a href={brands[key]} aria-label={key} >{key}</a></li>
                        )}
                    </ul>

                </div>

            </div>

        </React.Fragment>
    );
};
